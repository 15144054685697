<template>
  <b-container class="mt-4">
    <b-row>
      <b-col cols="10">
        <h4>SFTP {{ serverType === 'external' ? 'External' : 'Internal' }} Groups</h4>
      </b-col>
      <b-col cols="2">
        <b-button class="float-right" @click="() => this.$router.push('/sftp/create-group')" variant="primary">Create Group</b-button>
      </b-col>
    </b-row>
    <b-row v-if="loading">
      <b-col cols="12">
        <b-spinner></b-spinner>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col>
        <b-row class="mt-3" >
          <b-col cols="5">
            <b-form-group label="Group Name"
                          label-for="input-name">
              <b-form-input id="input-name"
                            v-model="filters.username"
                            type="text"/>
            </b-form-group>
          </b-col>
          <b-col cols="1" class="align-self-end">
            <div class="form-group">
              <b-button variant="primary" @click="searchByFilter" style="width: 100px">Search</b-button>
            </div>
          </b-col>
          <b-col cols="1" class="align-self-end">
            <div class="form-group">
              <b-button variant="warning" @click="resetFilters" style="width: 100px" class="ml-0">Reset</b-button>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-table striped hover :items="items" :fields="fields">
              <template #cell(users)="row">
                <span>{{ row.value.length }}</span>
              </template>
              <template #cell(actions)="row">
                <b-button
                  v-b-tooltip.hover
                  title="Show Details"
                  variant="primary"
                  size="sm"
                  :to="`/sftp/groups/${serverType}/${row.item.name}`">
                  See Details
                </b-button>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row class="pt-2">
          <b-col cols="6">
            <b-button @click="goToPreviousPage"
                      class="float-right" variant="primary" :disabled="currentPage === 1" style="width: 140px">Previous Page</b-button>
          </b-col>
          <b-col cols="6">
            <b-button @click="goToNextPage" variant="primary" :disabled="!hasNext" style="width: 140px">Next Page</b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex';
import pagination from '@/mixins/lists/pagination';
import * as R from 'ramda';

export default {
  name: 'SFTPGroupsList',
  mixins: [ pagination ],
  props: {
    serverType: {
      type: String,
    },
  },
  watch: {
    async serverType() {
      await this.search();
    },
  },
  async beforeMount() {
    if (!this.canAccessSftpUsersAndGroups) {
      this.$router.push('/not-found');
      return;
    }

    await this.search();
  },
  data() {
    return {
      loading: false,
      currentPage: 1,
      hasNext: true,
      limit: 10,
      filters: {
        username: null,
      },
      items: [],
      fields: [
        {
          key: 'name',
          label: 'Name',
        },
        {
          key: 'users',
          label: 'Number Of Users',
        },
        {
          key: 'actions',
          label: 'Actions',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      isSftpSettingsEnabled: 'Core/isSftpSettingsEnabled',
      canAccessSftpUsersAndGroups: 'User/canAccessSftpUsersAndGroups',
    }),
  },
  methods: {
    async search() {
      try {
        this.loading = true;
        const payload = {
          server: this.serverType,
          filters: this.filters,
          pagination: {
            limit: this.limit,
            offset: (this.currentPage - 1) * this.limit,
          },
        };

        const { data } = await this.$store.dispatch('Sftp/Groups/getGroups', payload);
        this.items = data;
        this.hasNext = this.items.length === 10;
      } catch (err) {
        console.error(err);
        this.$noty.error(`Unable to fetch users: ${R.pathOr(err, [ 'response', 'data', 'message' ], err)}`);
      } finally {
        this.loading = false;
      }
    },
    searchByFilter() {
      this.currentPage = 1;
      return this.search();
    },
    resetFilters() {
      this.filters = {
        username: null,
        email: null,
      };
      this.currentPage = 1;
      return this.search();
    },
    goToNextPage() {
      this.currentPage++;
      return this.search();
    },
    goToPreviousPage() {
      this.currentPage--;
      return this.search();
    },
  },
};
</script>
