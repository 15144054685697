export default {
  data() {
    return {
      pagination: {
        currentPage: 1,
        itemsPerPage: 10,
        totalRows: 0,
      },
    };
  },
  methods: {
    changePage(page) {
      this.pagination.currentPage = page;
      if (typeof this.onPageChange === 'function') {
        this.onPageChange();
      }
    },
  },
};
